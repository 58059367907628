<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-form-group class="col-12" :label="$t('name')">
                    <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                        <b-input-group class="mb-3" prepend="TR">
                            <b-form-input
                                v-model="form.name"
                                :class="errors[0] ? 'is-invalid' : ''" />
                        </b-input-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                    </ValidationProvider>
                    <ValidationProvider name="name_en" rules="required" v-slot="{ errors }">
                        <b-input-group prepend="EN">
                            <b-form-input
                                v-model="form.name_en"
                                :class="errors[0] ? 'is-invalid' : ''" />
                        </b-input-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                    </ValidationProvider>
                </b-form-group>
                <div class="col-12">
                    <ValidationProvider name="code" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('code')">
                            <b-form-input :class="errors[0] ? 'is-invalid' : ''" v-model="form.code" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('status')">
                            <b-form-select v-model="form.status" :options="options"></b-form-select>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </b-row>
        </ValidationObserver>
        <div class="col-12 mt-3 d-flex">
            <b-button
                @click="updateForm"
                type="button"
                variant="primary"
                class="btn-lg mr-2">
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import ExcelExportCrudService from "@/services/ExcelExportCrudService";
    // Components
    import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
    import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox.vue";

    export default {
        components: {
            StatusSelectbox,
            ValidationProvider,
            ValidationObserver,
            MultiSelectbox
        },
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                formLoading: false,
                form: {
                    code: null,
                    name: null,
                    name_en: null,
                    status: null
                },
                options: [
                    {text: this.$t('passive'), value: 0},
                    {text: this.$t('active'), value: 1},
                ],
            }
        },
        watch: {
            formId: function (val) {
                this.get(val)
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                ExcelExportCrudService.get(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                })
                                .catch((error) => {
                                    if (error.data.message) {
                                        this.$toast.error(this.$t("api." + error.data.message));
                                    }
                                }).finally(()=>{
                    this.formLoading = false;
                });
            },
            async updateForm() {
                if(this.checkPermission('excelexport_update')) {
                    const isValid = await this.$refs.formModalValidate.validate();
                    if (isValid && this.formId) {
                        this.formLoading=true;
                        ExcelExportCrudService.update(this.formId, this.form)
                                        .then((response) => {
                                            this.formLoading = false;
                                            this.$toast.success(this.$t("api." + response.data.message));
                                            this.$emit("updateFormSuccess")
                                        })
                                        .catch((error) => {
                                            this.showErrors(error, this.$refs.formModalValidate)
                                        }).finally(() => {
                            this.formLoading = false;
                        })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            }
        }
    }
</script>
