<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-form-group class="col-12" :label="$t('name')">
                    <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                        <b-input-group class="mb-3" prepend="TR">
                            <b-form-input v-model="form.name" :class="errors[0] ? 'is-invalid' : ''"/>
                        </b-input-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                    </ValidationProvider>
                    <ValidationProvider name="name_en" rules="required" v-slot="{ errors }">
                        <b-input-group prepend="EN">
                            <b-form-input v-model="form.name_en" :class="errors[0] ? 'is-invalid' : ''"/>
                        </b-input-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                    </ValidationProvider>
                </b-form-group>
                <div class="col-12">
                    <ValidationProvider name="code" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('code')">
                            <b-form-input :class="errors[0] ? 'is-invalid' : ''" v-model="form.code"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('status')">
                            <b-form-select v-model="form.status" :options="options"></b-form-select>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </b-row>
        </ValidationObserver>

        <div class="col-12 mt-3 d-flex">
            <b-button @click="createForm" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
//Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
//Service
import ExcelExportCrudService from "@/services/ExcelExportCrudService";
// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox.vue";

export default {
    components: {
        StatusSelectbox,
        ValidationProvider,
        ValidationObserver,
        MultiSelectbox
    },
    data() {
        return {
            formLoading: false,
            form: {
                code: null,
                name: null,
                name_en: null,
                status: null
            },
            options: [
                {text: this.$t('passive'), value: 0},
                {text: this.$t('active'), value: 1},
            ],
        }
    },
    methods: {
        async createForm() {
            if (this.checkPermission('excelexport_store')) {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    ExcelExportCrudService.store(this.form)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.$emit("createFormSuccess")
                        })
                        .catch((error) => {
                            this.showErrors(error, this.$refs.formModalValidate)
                        });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
    }
}
</script>
